import React, { useEffect, useState } from 'react';
import { Route, withRouter, Switch, Redirect, Link, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
//import MailIcon from '@mui/icons-material/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

//import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import AdminLink from './AdminLink';
import TabNavigation from './TabNavigation';
import { getAdminTabs, getUserTabs, getLogoHeader, getOrgMan } from '../../actions/API';
import { NotificationsInit } from '../../actions/NotificationActions';
// import AdminPage from '../../containers/AdminPage';
import TimeClockPage from '../../containers/TimeClockPage';
// import OrgManPage from '../../containers/OrgManPage';
// import UserPage from '../../containers/UserPage';
import Login from '../Login';
import ResetPasswordContainer from '../../containers/ResetPasswordContainer';
import ResetContainer from '../../containers/ResetContainer';
import FormContainer from '../../containers/FormContainer';
import AuthContainer from '../../containers/AuthContainer';
import useNavigate from '../../hooks/useNavigate';
import AccordionNavigation from './AccordionNavigation';

import MenuTabs from './MenuTabs';
import Drawer from './Drawer';
import Router from './Router';
import Ribbon from '../Common/Ribbon';
import { isMobile } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';

const iconsSVG = require('../../constants/iconsSVG.js');
const iconTypes = require('../../constants/iconTypes.js');

 
const drawerWidth = (process.env.REACT_APP_THEME == "epr") ? 240 : 0;

const useStyles = makeStyles()(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  appBarIcons: {
    color: '#000',
    marginRight: '10px',
  },

}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  background: `url(${require('../../img/header_background_main.jpg')}) no-repeat`,
  backgroundSize: 'cover',
  boxShadow: (process.env.REACT_APP_THEME == "epr") ? '2px 2px 5px #000' : 'none',
  minHeight: (process.env.REACT_APP_THEME == "epr") ? '56px !important': '74px !important',
  height: (process.env.REACT_APP_THEME == "epr") ? '56px !important': '74px !important',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const cookie = new Cookies();

const Header = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const anchorRef = React.useRef(null);

  const { classes } = useStyles();
  const [userTabs, setUserTabs] = React.useState([]);
  const [adminTabs, setAdminTabs] = React.useState([]);
  const [orgmanTabs, setOrgmanTabs] = React.useState([]); // Temporary solution for accessing orgman
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [logo, setLogo] = React.useState(process.env.REACT_APP_HEADER_LOGO);
  const [browserMessages, setBrowserMessages] = useState(0);
  const [calloutClass, setCalloutClass] = useState('svg-img svg-img-transparent');
  const [newCallouts, setNewCallouts] = useState(0);


  const open = Boolean(anchorEl);
  const isTimeclock = cookie.get('ndUserType') === 'timeclock';
  const isResetPassword = searchParams.get('op') === 'reset';
  const isSSO = searchParams.get('login') !== null;
  const isSSOWithUserToken = searchParams.get('user_id') && searchParams.get('token');
  const isOrgman = location.pathname == '/orgman';
  const incrementBrowserMessages = () => {setBrowserMessages(browserMessages+1);}
  const incrementCallouts = () => {
    setNewCallouts(newCallouts+1); 
    setCalloutClass('svg-img svg-img-red')
  }


  function navigationHandler(route) {
      // Check if it's in the same base path
      // TODO: utilize react router
      if (!window.location.pathname.includes(route)) {
        navigate(`/${route}`);
      }

      if (window.location.pathname.includes(route)) {
        // trigger loadJsonContent in Router
        props.globalActions.reloadJsonContent();
      }

  }

  useEffect(() => {
    if (isResetPassword || isSSO || isSSOWithUserToken) {
	    // console.log("Clearing key because");
	    // console.log(isResetPassword);
	    // console.log(isSSO);
	    // console.log(isSSOWithUserToken);
      props.globalActions.clearApiKey();
    }
  }, []);

  useEffect(() => {
    // DEV-918 always reload tabs to get new preLoadedData
    props.isLoggedIn && loadData();
  }, [props.history.location, props.isLoggedIn, getLogoHeader, logo]);

  if (process.env.REACT_APP_PUSH_NOTIFICATIONS === 'true') {
    NotificationsInit(props,incrementBrowserMessages,incrementCallouts);
  }

  function loadData() {
    (!userTabs || userTabs.length === 0) && getUserTabs().then(
      (userData) => {
        if (userData && userData.response && userData.response.status === 401 ) {
          cookie.remove('ndUserType', { path: '/' });
          cookie.remove('ndAPIKey', { path: '/' });
        } else {
          try {
            if (!isTimeclock) {
              const { usertabs, header } = userData?.data?.config;
              setLogo(header?.logo_filename || '');
              setUserTabs(usertabs || []);
            }
          } catch (e) {
            console.error('There was a problem loading the user tabs', e);
          }
        }
      },
    );
    (!adminTabs || adminTabs.length === 0) && getAdminTabs().then(
      (adminData) => {
        if ( adminData && adminData.response && adminData.response.status === 401 ) {
          cookie.remove('ndUserType', { path: '/' });
          cookie.remove('ndAPIKey', { path: '/' });
        } else {
          try {
            const { admintabs } = adminData?.data?.config;
            setAdminTabs(admintabs || []);
          } catch (e) {
            console.error('There was a problem loading the tabs', e);
          }
        }
      },
    );
    (!orgmanTabs || orgmanTabs.length === 0) && getOrgMan().then(
      (orgmanData) => {
        if ( orgmanData && orgmanData.response && orgmanData.response.status === 401 ) {
          cookie.remove('ndUserType', { path: '/' });
          cookie.remove('ndAPIKey', { path: '/' });
        } else {
          try {
            const orgmantabs = orgmanData?.data?.config.admintabs; 
            setOrgmanTabs(orgmantabs || []);
          } catch (e) {
            console.error('There was a problem loading the tabs', e);
          }
        }
      },
    );
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const submitLogout = () => {
    handleClose();
    props.globalActions.logout().then(() => {
      setUserTabs([]);
      setAdminTabs([]);
      setOrgmanTabs([]);
      props.history.replace('/login');
    });
  };

  const refreshCallouts = (setNewCallouts,setCalloutClass) => {
    setNewCallouts(0);
    setCalloutClass('svg-img svg-img-transparent');
    props.globalActions.fetchCallouts().then(() => {
      props.history.replace('/user/dashboard');
    });
  };

  const refreshNotifications = (setBrowserMessages) => {
    setBrowserMessages(0);
    props.globalActions.fetchNotifications().then(() => {
      props.history.replace('/user/dashboard');
    });
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getRouteWithTabs = ({ route = '', topmenu, label }, index) => {
    if (route == '')  {
      return ('');
    }

    if (process.env.REACT_APP_THEME == "epr") {
    return (
      <Route
        key={route.split('/').join('') + label + index}
        path={`/${route}`}
        render={routerProps => <TabNavigation globalActions={props.globalActions} tabData={topmenu} {...routerProps} />}
      />
    );
    } else {
      //console.log("getRouteWithTabs "+route);
      //console.log(userTabs);
    return (
      <Route
        key={route.split('/').join('') + label + index}
        path={`/${route}`}
        render={routerProps => <TabNavigation globalActions={props.globalActions} tabData={topmenu} {...routerProps} />}
      />
    );
    }
  };

  const getRoute = (tab, generalTabs) => {
    if (typeof(tab.route) != 'string')  {
      console.log("Error in "+JSON.stringify(tab));
      return ('');
    }
    if (tab.route == '')  {
      return ('');
    }
    return (
      <Route
        key={tab.route.split('/').join('') + tab.label}
        path={`/${tab.route}`}
        render={() => (
          <Router
            content={tab}
            preLoadedData={tab.data ? { data: tab.data } : undefined}
            tabs={generalTabs}
            globalActions={props.globalActions}
            history={props.history}
          />
        )}
      />
    );
  };

  const getAllRoutes = tabs => {
    const routes = tabs.map((tab, index) => {
      if (tab.ribbon) {
        // CONTENT WITH RIBBONS
        return (
          <Route
            key={tab.route.split('/').join('') + tab.label + index}
            path={`/${tab.route}`}
            render={routerProps => <Ribbon data={tab.ribbon} globalActions={props.globalActions} {...routerProps} />}
          />
        );
      }

      if (tab.topmenu) {
        // CONTENT WITH TABS
        return getRouteWithTabs(tab, index);
      }

      // STRAIGHT TO THE "ROUTER / COMPONENTPICKER"
      return getRoute(tab, tabs);
    });

    // Add 404 route
    if (routes.length) {
      return routes;
    }

    return [];
  };

  const handleToggle = () => {
    setOpenMenu(prevOpen => !prevOpen);
  };

  const handleCloseMenu = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  };


  const toolbar_styles = process.env.REACT_APP_THEME == "epr" ? 
    {display: 'flex', justifyContent: 'space-between', background: 'white' } :
    {display: 'flex', matginTop: "-30px", justifyContent: 'space-between', background: `url(${require('../../img/header_background_main.jpg')})`, backgroundSize: '100%' };

  const orgname_styles = process.env.REACT_APP_THEME == "epr" ? 
    { color: 'Highlight', flexGrow: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '2rem' } :
    { color: 'white', flexGrow: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '15px' };

  const logout_styles = process.env.REACT_APP_THEME == "epr" ? 
    { cursorPointer: 'none', fontWeight: 500, color: '#344054', fontSize: '16px', } :
    { cursorPointer: 'none', fontWeight: 300, color: '#ffffff', fontSize: '15px !important', fontFamily: `"Segoe UI", "Source Sans Pro", "Calibri", "Candara", "Arial", "sans-serif"`,};

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar open={drawerOpen}>
        { /* EPR Side Menu Version */ }
        { process.env.REACT_APP_THEME == "epr" ? (
          <Toolbar sx={toolbar_styles}>
            {props.isLoggedIn && !drawerOpen && process.env.REACT_APP_THEME == "epr" && (
              <IconButton edge="start" color={'black'} aria-label="menu" onClick={handleDrawerOpen}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
            <Box>
              <Link to={'/user/dashboard'}>
                <img
                  id="headerLogo"
                  alt="app logo"
                  src={require('../../img/' + logo)}
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                />
              </Link>
            </Box>
            <Box sx={ orgname_styles }>
              <Box sx={{ display: { xs: 'none', sm: 'initial' } }}>
                {props.displayData?.org_label}
              </Box>
            </Box>
            {props.isLoggedIn && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                }}>
                { ( process.env.REACT_APP_PUSH_NOTIFICATIONS === 'true' ) && ( 
                <>
                  <IconButton aria-label="show new messages" color="inherit"
                    onClick={() => refreshCallouts(setNewCallouts,setCalloutClass)}>
                    <Badge badgeContent={newCallouts} color="secondary">
                      <img className={classNames(calloutClass)} src={iconsSVG.CALLOUT} style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                    </Badge>
                  </IconButton>
                { /*<IconButton aria-label="show new notifications" color="inherit"
                    onClick={() => refreshNotifications(setBrowserMessages) }>
                    <Badge badgeContent={browserMessages} color="secondary">
                      <NotificationsIcon className={classes.appBarIcons} />
                    </Badge>
                  </IconButton>
                */ }
                </>
                )}
                <IconButton
                  ref={anchorRef}
                  aria-label="Switch tabs"
                  aria-controls={openMenu ? 'menu-list-grow' : undefined}
                  aria-haspopup="false"
                  sx={{
                    cursorPointer: 'none',
                    fontWeight: 100,
                    color: '#ffffff',
                    fontSize: '13px',
                  }}>
                  {' '}
                  <AdminLink path={props.location} has_orgman={orgmanTabs.length}/>
                </IconButton>
                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls={openMenu ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  sx={{
                    cursorPointer: 'none',
                    fontWeight: 500,
                    color: '#0071B5',
                    fontSize: '16px',
                  }}>
                  {' '}
                  {props.displayData && props.displayData.roster_label}
                </IconButton>
                <img
                  id="headerLogo"
                  alt="app logo"
                  src={require('../../img/vertcleSilverline.png')}
                  style={{
                    cursor: 'pointer',
                    height: 40
                  }}
                />

                <IconButton
                  ref={anchorRef}
                  aria-label="account of current user"
                  aria-controls={openMenu ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={submitLogout}
                  sx={logout_styles} >
                  Logout
                </IconButton>
              </Box>
            )}
          </Toolbar> ) : (
          <>
            { /* Netduty Tabbed Menu Version */ }
            <Toolbar sx={toolbar_styles}>
              <Grid container direction="column" sx={{marginTop: "-20px !important"}}>
                { /* First Row */ }
                <Grid item sx={{padding: "0 !important", marginTop: "0 !important"}} >
                  <Grid container alignItems="center" spacing={2} sx={{marginTop: '10px'}}>
                    <Grid item sx={{paddingLeft: "0 !important"}}>
                      <Link to={'/user/dashboard'}>
                        <img
                          id="headerLogo"
                          alt="app logo"
                          src={require('../../img/' + logo)}
                          style={{
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                        />
                      </Link>
                    </Grid>
                    <Grid item sx={ orgname_styles }>
                      <Box sx={{ display: { xs: 'none', sm: 'initial'} }}>
                        {props.displayData?.org_label}
                      </Box>
                    </Grid>
                    {props.isLoggedIn && (
                      <Grid item>
                        <IconButton
                          ref={anchorRef}
                          aria-label="account of current user"
                          aria-controls={openMenu ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          sx={{
                            cursorPointer: 'none',
                            fontWeight: 300,
                            fontFamily: `"Segoe UI", "Source Sans Pro", "Calibri", "Candara", "Arial", "sans-serif"`,
                            color: 'white',
                            fontSize: '12px',
                          }}>
                          {' '}
                          {props.displayData && props.displayData.roster_label}
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                { /* Second Row */ }
                {props.isLoggedIn && (
                  <Grid item>
                    <Grid container alignItems="center" sx={{paddingTop: "0 !important", marginTop: "-10px !important"}}>
                      <Grid item sx={{paddingTop: "0 !important"}} >
                        <AccordionNavigation 
                        currentPage={props.currentPage} 
                        userTabs={userTabs} 
                        adminTabs={adminTabs} 
                        orgmanTabs={orgmanTabs} 
                        navigationHandler={navigationHandler} 
                        currentPage={props.currentPage} 
                        view={props.view} />
                      </Grid>
                      <Grid item sx={{ marginLeft: "auto" }}>
                        { ( process.env.REACT_APP_PUSH_NOTIFICATIONS === 'true' ) && ( 
                        <>
                          <IconButton aria-label="show new messages" color="inherit"
                            onClick={() => refreshCallouts(setNewCallouts,setCalloutClass)}>
                            <Badge badgeContent={newCallouts} color="secondary">
                              <img className={classNames(calloutClass)} src={iconsSVG.CALLOUT} style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                            </Badge>
                          </IconButton>
                        { /*<IconButton aria-label="show new notifications" color="inherit"
                            onClick={() => refreshNotifications(setBrowserMessages) }>
                            <Badge badgeContent={browserMessages} color="secondary">
                              <NotificationsIcon className={classes.appBarIcons} />
                            </Badge>
                          </IconButton>
                        */ }
                        </>
                        )}
                        <IconButton
                          ref={anchorRef}
                          aria-label="Switch tabs"
                          aria-controls={openMenu ? 'menu-list-grow' : undefined}
                          aria-haspopup="false"
                          sx={{
                            cursorPointer: 'none',
                            fontWeight: 100,
                            fontFamily: `"Segoe UI", "Source Sans Pro", "Calibri", "Candara", "Arial", "sans-serif"`,
                            color: '#ffffff',
                            fontSize: '15px',
                          }}>
                          {' '}
                          { props.displayData && props.displayData.admin_link && (
                              <AdminLink path={props.location} has_orgman={orgmanTabs.length}/>
                          )}
                        </IconButton>
                        <IconButton
                          ref={anchorRef}
                          aria-label="account of current user"
                          aria-controls={openMenu ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={submitLogout}
                          sx={logout_styles} >
                          Logout
                        </IconButton>
                      </Grid> 
                    </Grid>
                  </Grid> 
                )}
              </Grid>
            </Toolbar> 
          </>
          )}
          {(location.pathname == '/user/dashboard' || location.pathname == '/orgman/dashboard/') && process.env.REACT_APP_THEME == "epr" ? (
            <Toolbar
              sx={{
                background: '#63A7AA',
                fontstyle: 'normal',
                fontWeight: 300,
                fontSize: '22px',
                lineHeight: '38px',
                textTransform: 'uppercase',
              }}>
              Dashboard
            </Toolbar>
          ) : null}
        </AppBar>
        {props.isLoggedIn && process.env.REACT_APP_THEME == "epr" && (
          <Drawer
            jsonTabs={userTabs}
            adminTabs={adminTabs}
            orgmanTabs={orgmanTabs}
            drawerOpen={drawerOpen}
            handleDrawerClose={handleDrawerClose}
            globalActions={props.globalActions}
          />
        )
        }
        <Box
          component="main"
          sx={{
            flexDirection: 'column',
              marginTop: process.env.REACT_APP_THEME == "epr" ? 'auto' : '10px',
              overFlowX: process.env.REACT_APP_THEME == "epr" ? 'auto' : 'hidden',
            width: getMainLayoutWidth(drawerOpen, isMobile, props.currentPage),
          }}>

          <div className={classes.toolbar} />
          {props.isLoggedIn && process.env.REACT_APP_THEME == "netduty" && (
            <MenuTabs 
              globalActions={props.globalActions}
              userTabs={userTabs} 
              adminTabs={adminTabs} 
              orgmanTabs={orgmanTabs} 
              navigationHandler={navigationHandler} 
              currentPage={props.currentPage} 
              />
          )}
          {!props.checkingCookie &&
            (props.isLoggedIn ? (
              <AuthContainer>
                {isTimeclock ? (
                  <Switch>
                    <Route path="/timeclock" component={TimeClockPage} />
                    <Route path="*">
                      <Redirect to="/timeclock" />
                    </Route>
                  </Switch>
                ) : (
                  <Switch>
                    {userTabs.length > 0 && getAllRoutes(userTabs)}
                    {adminTabs.length > 0 && getAllRoutes(adminTabs)}
                    {orgmanTabs.length > 0 && getAllRoutes(orgmanTabs)}
                    {orgmanTabs.length > 0 && (
                      <Route path={'/orgman'}>
                        <Redirect to={'/orgman/dashboard'} />
                      </Route>
                    )}
                    {userTabs.length > 0 && (
                      <Route path={'*'}>
                        <Redirect to={'/user/dashboard'} />
                      </Route>
                    )}
                  </Switch>
                )}
              </AuthContainer>
            ) : (
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/reset_password" component={ResetPasswordContainer} />
                <Route path="/reset" component={ResetContainer} />
                <Route path="/form" component={FormContainer} />
                {isResetPassword && <Redirect to={`/reset_password${location.search}`} />}
                {isSSO && <Redirect to={`/login${location.search}`} />}
                {isSSOWithUserToken && <Redirect to={`/login${location.search}`} />}
                <Route path="*">
                  <Redirect to={'/login'} />
                </Route>
              </Switch>
            ))}
        </Box>
      </Box>
    </>
  );
};

export default withRouter(Header);

function getMainLayoutWidth(isDrawerOpen, isMobile, currentPage) {
  if (currentPage === '/login' || isMobile) {
    return '100%';
  }
  
  return (process.env.REACT_APP_THEME == "epr") ? `calc(100% - ${isDrawerOpen ? 240 : 65}px)` : `calc(100% - ${isDrawerOpen ? 240 : 0}px)` ;
}
